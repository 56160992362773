<!--
 * @Author: your name
 * @Date: 2021-12-08 09:47:36
 * @LastEditTime: 2021-12-08 09:47:37
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ansu-business/src/views/ConfigInfo/index.vue
-->
<template>
    <router-view></router-view>
</template>
<script>
export default {
    name: "ConfigInfo"
}
</script>

<style lang="less" scoped>

</style>